import { mapGetters } from "vuex";
import navLi from "../header/components/burger-nav/index.vue";

export default {
    name: "main-footer",
    data() {
        return {
            showIosAppPopup: false,
            showAndroidAppPopup: false,
            showOpSystemPopUp: false,
        };
    },
    components: {
        navLi,
    },
    computed: {
        ...mapGetters({
            contacts: "setting/variables",
            headerMenu: "setting/headerMenu",
            isDistributor: "profile/isDistributor",
            isProfessional: "profile/isProfessional",
            globalLoader: "system/globalLoader",
        }),
    },
    mounted() {
        this.getMobileOperatingSystem();
    },
    methods: {
        /**
         * @param {keyof GeneralContacts|string} field
         * @return {string}
         */
        closeAppPopup() {
            this.showOpSystemPopUp = false;
        },
        getMobileOperatingSystem() {
            if (!localStorage.getItem("userMobileOperatingSystem")) {
                let userAgent = navigator.userAgent || navigator.vendor || window.opera;
                let userMobileOperatingSystem;

                if (/android|Android/i.test(userAgent)) {
                    userMobileOperatingSystem = "Android";
                }
                if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                    userMobileOperatingSystem = "iOS";
                }
                if (!(/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) && !/android|Android/i.test(userAgent)) {
                    return (userMobileOperatingSystem = undefined);
                }
                if (userMobileOperatingSystem) {
                    if (userMobileOperatingSystem === "iOS") {
                        localStorage.setItem("userMobileOperatingSystem", userMobileOperatingSystem);
                        setTimeout(() => {
                            this.showOpSystemPopUp = true;
                            return (this.showIosAppPopup = true);
                        }, 3000);
                    }
                    if (userMobileOperatingSystem === "Android") {
                        setTimeout(() => {
                            this.showOpSystemPopUp = true;
                            return (this.showAndroidAppPopup = true);
                        }, 3000);
                    }
                }
            }
        },
        getContact(field) {
            return this.contacts?.contacts[field] || "";
        },
    },
};
