// import mainInput from '../../atoms/main-input/index.vue'
import { mapActions, mapGetters, mapMutations } from "vuex";
import { maxLength, minLength, required } from "vuelidate/lib/validators";
import validation from "@/mixins/validation";
import PhoneMaskInput from "vue-phone-mask-input";
import { phoneNumberValidation } from '../../../mixins/validation'

export default {
  name: 'seminar-registration',
  data() {
    return {
      payload: {
        name: '',
        comment: '',
        seminar_id: '',
        surname: '',
        phone: '',
      },
      validationErrors: {},
      inputRefs: [],
    }
  },
  mixins: [validation],
  validations: {
    payload: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(120),
      },
      phone: {
        required,
        phoneNumberValidation,
        minLength: minLength(8),
      },
      surname: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(120),
      },
    },
  },
  components: {
    PhoneMaskInput,
  },
  mounted() {
    this.payload.name = this.user.name
    this.payload.phone = this.user.phone.replace(/[^+\d]/g, '')
    this.payload.surname = this.user.secondName
    this.$refs.phonemask.$el.querySelector('input').value = this.user.phone.replace(/[^+\d]/g, '')
  },
  computed: {
    ...mapGetters({
      seminarData: 'seminars/currentSeminar',
      user: 'profile/user',
    }),
    phoneErrors() {
      return this.computeErrors('payload', 'phone', {
        required: 'validationRequired',
        phoneNumberValidation: 'phoneNumberValidation',
        minLength: 'validationMin',
      })
    },
    nameErrors() {
      return this.computeErrors('payload', 'name', {
        maxLength: { message: 'validationMax', count: 120 },
        required: 'validationRequired',
      })
    },
    surnameErrors() {
      return this.computeErrors('payload', 'surname', {
        maxLength: { message: 'validationMax', count: 120 },
        required: 'validationRequired',
      })
    },
  },
  created() {
    document.getElementById('html').classList.add('hide')
  },
  methods: {
    ...mapMutations({
      changeSeminarSuccess: 'popups/SHOW_SUCCESS_SEMINAR',
    }),
    ...mapActions({
      seminarRegistration: 'seminars/SEMINAR_REGISTRATION',
    }),
    setItemRef(el) {
      if (el) this.inputRefs.push(el)
    },
    submit() {
      this.$v.payload.$touch()
      if (this.$v.payload.$invalid) return

      this.payload.seminar_id = this.seminarData.id
      this.seminarRegistration(this.payload).then(() => {
        document.getElementById('html').classList.remove('hide')

        this.changeSeminarSuccess(true)

        this.$toasted.success(this.$t('registrationIsSuccessful'))
      })
    },
  },
}
