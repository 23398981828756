import Vue from "vue";
import VueI18nManager from "vue-i18n-manager";
import store from "@/store";
import router from "@/router";

const proxy = {
    getTranslation: async lang => {
        return await store.dispatch(`setting/GET_TRANSLATIONS`, lang.key);
    },
};

Vue.use(VueI18nManager, {
    store,
    router,
    proxy,
    config: {
        defaultCode: "cz-CZ",
        languages: [
            {
                name: "Czech",
                title: "CZ",
                key: "cz",
                code: "cz-CZ",
                urlPrefix: "cz",
                translationKey: "cz",
            },
            {
                name: "English",
                title: "EN",
                key: "en",
                code: "en-US",
                urlPrefix: "en",
                translationKey: "en",
            },
            {
                name: "Russian",
                title: "RU",
                key: "ru",
                code: "ru-RU",
                urlPrefix: "ru",
                translationKey: "ru",
            },
            {
                name: "Bulgarian",
                title: "BG",
                key: "bg",
                code: "bg-BG",
                urlPrefix: "bg",
                translationKey: "bg",
            },
            {
                name: "Danish",
                title: "DA",
                key: "da",
                code: "da-DK",
                urlPrefix: "da",
                translationKey: "da",
            },
            {
                name: "German",
                title: "DE",
                key: "de",
                code: "de-DE",
                urlPrefix: "de",
                translationKey: "de",
            },
            {
                name: "Greek",
                title: "EL",
                key: "el",
                code: "el-GR",
                urlPrefix: "el",
                translationKey: "el",
            },
            {
                name: "Spanish",
                title: "ES",
                key: "es",
                code: "es-ES",
                urlPrefix: "es",
                translationKey: "es",
            },
            {
                name: "Estonian",
                title: "ET",
                key: "et",
                code: "et-EE",
                urlPrefix: "et",
                translationKey: "et",
            },
            {
                name: "Finnish",
                title: "FI",
                key: "fi",
                code: "fi-FI",
                urlPrefix: "fi",
                translationKey: "fi",
            },
            {
                name: "French",
                title: "FR",
                key: "fr",
                code: "fr-FR",
                urlPrefix: "fr",
                translationKey: "fr",
            },
            {
                name: "Irish",
                title: "GA",
                key: "ga",
                code: "ga-IE",
                urlPrefix: "ga",
                translationKey: "ga",
            },
            {
                name: "Croatian",
                title: "HR",
                key: "hr",
                code: "hr-HR",
                urlPrefix: "hr",
                translationKey: "hr",
            },
            {
                name: "Hungarian",
                title: "HU",
                key: "hu",
                code: "hu-HU",
                urlPrefix: "hu",
                translationKey: "hu",
            },
            {
                name: "Italian",
                title: "IT",
                key: "it",
                code: "it-IT",
                urlPrefix: "it",
                translationKey: "it",
            },
            {
                name: "Luxembourgish",
                title: "LB",
                key: "lb",
                code: "lb-LU",
                urlPrefix: "lb",
                translationKey: "lb",
            },
            {
                name: "Lithuanian",
                title: "LT",
                key: "lt",
                code: "lt-LT",
                urlPrefix: "lt",
                translationKey: "lt",
            },
            {
                name: "Latvian",
                title: "LV",
                key: "lv",
                code: "lv-LV",
                urlPrefix: "lv",
                translationKey: "lv",
            },
            {
                name: "Maltese",
                title: "MT",
                key: "mt",
                code: "mt-MT",
                urlPrefix: "mt",
                translationKey: "mt",
            },
            {
                name: "Dutch",
                title: "NL",
                key: "nl",
                code: "nl-NL",
                urlPrefix: "nl",
                translationKey: "nl",
            },
            {
                name: "Polish",
                title: "PL",
                key: "pl",
                code: "pl-PL",
                urlPrefix: "pl",
                translationKey: "pl",
            },
            {
                name: "Portuguese",
                title: "PT",
                key: "pt",
                code: "pt-PT",
                urlPrefix: "pt",
                translationKey: "pt",
            },
            {
                name: "Romanian",
                title: "RO",
                key: "ro",
                code: "ro-RO",
                urlPrefix: "ro",
                translationKey: "ro",
            },
            {
                name: "Slovak",
                title: "SK",
                key: "sk",
                code: "sk-SK",
                urlPrefix: "sk",
                translationKey: "sk",
            },
            {
                name: "Slovenian",
                title: "SL",
                key: "sl",
                code: "sl-SI",
                urlPrefix: "sl",
                translationKey: "sl",
            },
            {
                name: "Swedish",
                title: "SV",
                key: "sv",
                code: "sv-SE",
                urlPrefix: "sv",
                translationKey: "sv",
            },
            {
                name: "Turkish",
                title: "TR",
                key: "tr",
                code: "tr-TR",
                urlPrefix: "tr",
                translationKey: "tr",
            },
        ],
        translations: proxy,
    },
});
