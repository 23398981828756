import type { ActionContext } from "vuex";
import type { RootState } from "@/store";
import { $http } from "@/utils/https";

interface State {
}

const state: State = {
}

const getters = {
}

const actions = {
    async ESPUTNIK_SUBSCRIBE({ commit }: ActionContext<State, RootState>, { locale, email }: { locale: string, email: string }) {
        try {
            return await $http.post('v1/subscribe_email', { locale, email });
        } catch (error) {
            console.error('ESputnik | Failed to subscribe email:', error);
            throw error;
        }
    },
}

const mutations = {
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
