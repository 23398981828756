<template>
  <div class="together__product">
    <router-link :to="$localize(routeToProduct(product, getLargeValue(product.productPrices.data)))">
      <div class="together__product_img">
        <img loading="lazy" :src="selectedProductPrice.image" alt="" />
      </div>
    </router-link>
    <h2 :title="product.title">{{ product.title }}</h2>
    <type-select style="width: 100px; margin-inline: auto;" v-if="product.productPrices.data.length > 1"
      :list="product.productPrices.data" item-value="id" item-title="value" type="objectData"
      v-model="selectedProductPrice" :value="selectedProductPrice" />
    <div style="height: 26px;" v-else>
        <div class="select select-placeholder" style="width: 100px; margin-inline: auto;">
            <input style="height: 26px;" type="text" readonly="readonly" :value="product.productPrices.data[0].title">
        </div>
    </div>
    <h3>{{ selectedProductPrice.price.toFixed(2) }} {{ $currency(selectedProductPrice.currency) }}</h3>
    <button @click="addBasket(product)">{{ $t('buy') }}</button>
  </div>
</template>

<style lang="scss">
.select-placeholder {
    input {
        pointer-events: none;
        padding: 0 10px 0 10px;
        text-align: center;
    }

    &:after {
        display: none;
    }
}
</style>

<script>
import { routeToProduct, getProductPriceTitle } from "@/helpers";
import { mapMutations } from "vuex";
import TypeSelect from "@/components/product/components/select/index.vue";

export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  components: {
    TypeSelect,
  },
  data() {
    return {
      selectedProductPrice: this.product.productPrices.data[0],
    };
  },
  methods: {
    ...mapMutations({
      changeBasketPopup: "popups/CHANGE_BASKET_POPUP",
      addToBasket: `basket/ADD_ITEM_TO_BASKET`,
    }),
    routeToProduct,
    getProductPriceTitle,
    toProduct(product) {
      document.getElementById("html").classList.remove("hide");
      this.changeBasketPopup(false);
    },
    addBasket(product) {
      this.productsKey += 1;

      if (product.hasDuplicate) {
        this.$toasted.info(this.$t("thisProductHasBeenMovedToANewSeriesAndCannotBePurchased"));
        return;
      }

      this.addToBasket({
        select_count: 1,
        product_price: this.selectedProductPrice,
        product: product,
      });
    },
    getLargeValue(products) {
      return products[0];
    },
  },
};
</script>
