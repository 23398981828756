import { mapKeys, snakeCase } from "lodash";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { email, maxLength, minLength, required } from "vuelidate/lib/validators";
import PhoneMaskInput from "vue-phone-mask-input";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { RegisterMode } from "@/components/popups/registration";
import validation from "@/mixins/validation";
import { TheMask } from "vue-the-mask";
import { $http } from "../../../utils/https";
import { phoneNumberValidation, latinOnly, latinOnlyWithSymbols} from "../../../mixins/validation";

export default {
    name: "agent-registration",

    components: {
        PhoneMaskInput,
        TheMask
    },

    data() {
        return {
            agree: false,
            payload: {
                register_mode: RegisterMode.Professional,
                assign_to: "",
                email: "",
                name: "",
                lastName: "",
                phone: "",
                workName: "",
                workAddress: "",

                phone_country: "",
                countryCode: null,
                country: null,
                selectedCountry: null,

                city: null,
                selectedCity: null,

                areaRef: "",
                certificateNumber: '',
                workWithVatDisplay: null,
                vatNumber: null,
                icoNumber: '',
                icoConfirmToken: ''
            },

            showPassword: false,
            showRepeatPassword: false,
            showVatNumber: false,
            passwordErrors: [],
            repeatPasswordErrors: [],
            password: '',
            repeatPassword: '',
            phoneMaskCode: new Date().getTime(),
            countries: [],
            filterCountries: [],
            isValid: false,
            isValidTown: false,
            validationErrors: {},
        };
    },
    mixins: [validation],
    validations() {
        const validation = {
            payload: {
                name: {
                    required,
                    latinOnly,
                },
                lastName: {
                    required,
                    latinOnly,
                },
                phone: {
                    required,
                    minLength: minLength(8),
                    phoneNumberValidation
                },
                country: {
                    required,
                },
                selectedCountry: {
                    required,
                },
                city: {
                    required,
                },
                selectedCity: {
                    required,
                },
                certificateNumber: {
                    required,
                    latinOnlyWithSymbols,
                },
                workWithVatDisplay: {
                    required,
                },
                email: {
                    required,
                    maxLength: maxLength(120),
                    email,
                },
                workName: {
                    latinOnlyWithSymbols,
                },
                workAddress: {
                    latinOnlyWithSymbols,
                },
            }
        }
        if (this.showVatNumber) {
            validation.payload.vatNumber = {
                required,
                // minLength: minLength(8),
            }
        }
        return validation;
    },
    watch: {
        agree() {
            this.$refs.agreeError.innerHTML = !this.agree ? this.$t("youMustAgreeToThePrivacyPolicy") : "";
        },
        "payload.countryCode"() {
            this.$refs.phonemask.$forceUpdate();
            this.phoneMaskCode = new Date().getTime();
            this.$refs.phonemask.$el.querySelector("input").value = "";
            this.payload.phone = "";
        },
        "password"() {
            this.passwordErrors = [];
        },
        "repeatPassword"() {
            this.repeatPasswordErrors = [];
        },
        "payload.workWithVatDisplay"() {
            this.payload.vatNumber = '';
        }
    },
    created() {
        this.payload.phone = this.verifyCodeResponse?.phone;
        document.getElementById("html").classList.remove("hide");
        this.onRequestTown(this.payload.city);
        this.getCountries();

        this.fetchCountries().then(res => {
            this.countries = res;
            this.filterCountries = res;
        });
    },
    computed: {
        ...mapGetters({
            loadingRegistration: "auth/loadingRegistration",
            agentCode: "auth/agentCode",
            towns: "order/towns",
            verifyCodeResponse: "auth/verifyCodeResponse",
            cityList: "country/cityList",
        }),
        nameErrors() {
            return this.computeErrors("payload", "name", {
                required: "validationRequired",
                latinOnly: "onlyLatinLettersAllowed"
            });
        },
        lastNameErrors() {
            return this.computeErrors("payload", "lastName", {
                required: "validationRequired",
                latinOnly: "onlyLatinLettersAllowed"
            });
        },
        phoneErrors() {
            return this.computeErrors("payload", "phone", {
                required: "validationRequired",
                phoneNumberValidation: "phoneNumberValidation",
                minLength: { message: "validationMin", count: 8 },
            });
        },
        countryErrors() {
            return this.computeErrors("payload", "selectedCountry", {
                required: "validationRequired",
            });
        },
        workWithVatDisplayErrors() {
            return this.computeErrors("payload", "workWithVatDisplay", {
                required: "validationRequired",
            });
        },
        cityErrors() {
            return this.computeErrors("payload", "selectedCity", {
                required: "validationRequired",
            });
        },
        emailErrors() {
            return this.computeErrors("payload", "email", {
                maxLength: { message: "validationMax", count: 120 },
                required: "validationRequired",
                email: "validationEmail",
            });
        },
        workNameErrors() {
            return this.computeErrors("payload", "workName", {
                latinOnlyWithSymbols: "onlyLatinLettersAllowed"
            });
        },
        workAddressErrors() {
            return this.computeErrors("payload", "workAddress", {
                latinOnlyWithSymbols: "onlyLatinLettersAllowed"
            });
        },
        certificateNumberErrors() {
            return this.computeErrors("payload", "certificateNumber", {
                required: "validationRequired",
                latinOnlyWithSymbols: "onlyLatinLettersAllowed"
            });
        },
        vatNumberErrors() {
            return this.computeErrors("payload", "vatNumber", {
                required: "validationRequired",
                // minLength: { message: "validationMin", count: 8 },
            });
        },
    },
    methods: {
        ...mapActions({
            registrations: "auth/REGISTRATION",
            getCountries: "auth/GET_COUNTRIES",
            fetchTowns: "order/NOVA_POSHTA_GET_TOWNS",
            fetchCountries: "country/GET_COUNTRY_LIST",
            fetchCities: "country/GET_CITY_LIST",
        }),
        ...mapMutations({
            changeRegisterAgent: "popups/CHANGE_SHOW_SUCCESS_AGENT_REGISTER",
            changeLoginPopup: "popups/CHANGE_LOGIN_POPUP",
            changeCertificateProblemPopup: "popups/CHANGE_CERTIFICATE_PROBLEM_POPUP"
        }),
        handleCountyInput(e) {
            if (e.length < 3) {
                this.filterCountries = this.countries.slice();
                return;
            }
            this.filterCountries = this.countries.filter(item => item.name.toLowerCase().includes(e.toLowerCase()));
        },

        handleCityInput(e) {
            if (e.length < 3) {
                this.fetchCities({ code: this.payload.countryCode }).then(res => console.log(res));
            }
            this.fetchCities({ code: this.payload.countryCode, search: e }).then(res => console.log(res));
        },

        handleSelectCountry(item) {
            this.payload.countryCode = item.code;
            this.payload.selectedCountry = item.name;
            this.payload.city = '';
            this.fetchCities({ code: item.code }).then(res => console.log(res));
        },
        handleSelectCity(item) {
            this.payload.selectedCity = item.name;
        },
        handleSelectVatValue(value) {
            this.showVatNumber = value.value;
        },
        onRequestTown(val) {
            const formData = JSON.stringify({
                modelName: "Address",
                calledMethod: "getCities",
                methodProperties: {
                    FindByString: val,
                },
                apiKey: process.env.VUE_APP_NOVAPOSHTA_API_KEY,
            });
            this.fetchTowns(formData).then(() => {
                const currentTown = this.towns.find(e => e.Description === this.payload.city);

                console.log(currentTown);

                if (currentTown) {
                    this.payload.areaRef = currentTown.Area;
                } else {
                    this.payload.areaRef = "";
                }
            });
        },

        // handleFileChange(evt) {
        //     let arr = Array.from(evt.target.files);
        //     this.payload.files = this.payload.files.concat(arr);
        // },
        // removeFile(file) {
        //     const index = this.payload.files.findIndex(e => e.name === file.name);
        //     this.payload.files.splice(index, 1);
        // },
        transformData() {
            let parsedNumber = '';

            try {
                const phone = this.payload.phone;
                if (phone) {
                    parsedNumber = parsePhoneNumberFromString(phone.startsWith('+') ? phone : '+' + phone);
                }
            } catch (error) {
                console.error('Error parsing phone number:', error);
                parsedNumber = '';
            }

            if (parsedNumber) {
                this.payload.phone_country = parsedNumber.country;
            } else {
                this.payload.phone_country = "";
            }

            if (!this.showVatNumber) {
                this.payload.vatNumber = '';
            }

            this.payload.password = this.password;
            this.payload.password_confirmation = this.password;
            this.payload['workWithVat'] = this.showVatNumber === true ? 1 : 0;

            this.payload.confirm_token = this.verifyCodeResponse?.confirmToken;
            return mapKeys(this.payload, (value, key) => snakeCase(key));
        },
        parsFormData(obj) {
            let fd = new FormData();
            for (let i in obj) {
                if (i === "files") {
                    for (let j in obj[i]) {
                        fd.append(`files[${j}]`, obj[i][j]);
                    }
                } else {
                    fd.append(i, obj[i]);
                }
            }

            return fd;
        },
        async fetchIcoNumberCompanyInfo() {
            try {
                this.payload.icoConfirmToken = '';
                // this.$v.payload.icoNumber.$reset();
                delete this.validationErrors['icoNumber'];
                if (this.payload.icoNumber.length !== 8) return;

                const response = await $http.post('/v1/auth/verify-ico', {
                    "ico_number": this.payload.icoNumber
                })

                this.payload.icoConfirmToken = response.data['icoConfirmToken'];

                const country = this.countries.find(item => item.code === response.data.companyInfo.country)
                this.payload.country = country.name;
                this.payload.countryCode = response.data.companyInfo.country;
                this.payload.selectedCountry = country.name;
                this.payload.selectedCity = response.data.companyInfo.country;
                this.payload.city = response.data.companyInfo.city;
                this.payload.workName = response.data.companyInfo.name || '';
                this.payload.workAddress = response.data.companyInfo.address;
            } catch (error) {
                console.error('Error fetching ICO number company info:', error);
                this.handleRegistrationErrors(error)
                this.$v.payload.icoNumber.$touch()
            }
        },
        removeAttr(ref) {
            this.$refs[`${ref}`].removeAttribute("readonly");
        },
        async submit() {
            this.$refs.agreeError.innerHTML = !this.agree ? this.$t("youMustAgreeToThePrivacyPolicy") : "";
            this.$v.$touch();
            this.validationErrors = [];
            this.passwordErrors = [];
            this.repeatPasswordErrors = [];

            if (this.password.length < 8) this.passwordErrors.push(this.$t('validationMin').replace(':count', 8))
            if (this.repeatPassword.length < 8) this.repeatPasswordErrors.push(this.$t('validationMin').replace(':count', 8))
            if (this.password !== this.repeatPassword) this.repeatPasswordErrors.push(this.$t('validationSameAs'))


            if (this.$v.$invalid) {
                console.log(this.$v.$invalid, this.$v)
                this.$toasted.error(this.$t("fillInAllRequiredFields"));
                return;
            }

            if (!this.agree) {
                this.$toasted.error(this.$t("agreeToThePrivacyPolicy"));
                return;
            }

            if (this.passwordErrors.length || this.repeatPasswordErrors.length) return;

            try {
                await this.registrations(this.parsFormData(this.transformData()));
                this.changeRegisterAgent(true);
            } catch (error) {
                this.handleCertificateProblem(error);
                this.handleRegistrationErrors(error);
            }
        },

        handleCertificateProblem(error) {
            if (error.status === 422) {
                if (Object.keys(error.data.errors).length === 1 && error.data.errors['certificateNumber']) {
                    this.changeCertificateProblemPopup({ status: true, payload: this.transformData() });
                }
            }
        },

        handleRegistrationErrors(error) {
            if (!error?.data?.errors) return;

            const processErrors = ["email", "phone", "assignTo", "icoNumber", "vatNumber"];
            for (const processErrorsKey of processErrors) {
                const errors = error.data.errors[processErrorsKey];
                if (!errors || !errors[0]) {
                    continue;
                }

                if (processErrorsKey !== "certificate_number") {
                    this.validationErrors[processErrorsKey] = errors;
                }

                this.$toasted.error(errors[0]);
            }
        },
    },
};
