// import mainInput from '../../atoms/main-input/index.vue'
// import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from "vuex";
// import {camelCase} from "lodash";

import PhoneMaskInput from "vue-phone-mask-input";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import validation from "@/mixins/validation";

export default {
    name: "sms-popup",

    data() {
        return {
            vuePhone: {
                data: null,
                props: {
                    clearable: true,
                    fetchCountry: true,
                    preferredCountries: ["US", "GB"],
                    noExample: false,
                    translations: {
                        countrySelectorLabel: "Country code",
                        countrySelectorError: "Error",
                        phoneNumberLabel: "Enter your phone",
                        example: "Example:",
                    },
                },
            },
            phone: "",
            agree: false,
            registrationType: 2,
            countryCode: "UA",
            payload: {
                sms: "",
                phone: "",
            },
        };
    },

    components: {
        PhoneMaskInput,
        VueTelInput,
    },
    watch: {
        "payload.phone": function() {
            this.$refs.smsError.innerHTML = "";
            this.$refs.phoneError.innerHTML = "";
        },
        "payload.sms": function() {
            this.$refs.smsError.innerHTML = "";
        },
        phone(value) {
            const parsedNumber = parsePhoneNumberFromString(value);
            if (parsedNumber) {
                this.countryCode = parsedNumber.country;
            } else {
                this.countryCode = "";
            }
        },
    },
    computed: {
        ...mapGetters({
            smsCodeRequested: "auth/smsCodeRequested",
            verifyCodeResponse: "auth/verifyCodeResponse",
            verifyCodeLoading: "auth/verifyCodeLoading",
            smsCodeLoading: "auth/smsCodeLoading",
        }),
    },
    created() {
        document.getElementById("html").classList.add("hide");
    },
    methods: {
        ...mapMutations({
            changeRegistrationPopup: "popups/CHANGE_REGISTRATION_POPUP",
            changeLoginPopup: "popups/CHANGE_LOGIN_POPUP",
            changeSuccessRegisterPopup: "popups/CHANGE_SUCCESS_REGISTER_POPUP",
            changeRegisterAgentPopup: "popups/CHANGE_AGENT_REGISTER_POPUP",
        }),
        ...mapActions({
            sendPhone: "auth/SEND_CODE",
            sendCode: "auth/VERIFY_CODE",
        }),
        async sendSmsCode() {
            // if (!this.countryCode) {
            //     /*invalid phone*/
            //     this.$refs.phoneError.innerHTML = this.$t("minimumNumberOfCharacters") + " - 13";
            //     return;
            // }

            try {
                const response = await this.sendPhone({
                    phone: this.phone,
                    phone_country: this.countryCode,
                });

                /** @type {ISendCodeResponse} */
                const data = response.data;

                this.$refs.smsError.innerHTML = "";
                this.$toasted.success(data.message);

                if (data.codeMessage) {
                    this.$toasted.success(data.codeMessage);

                    this.payload.sms = data.debug.code;
                }
            } catch (error) {
                this.$refs.smsError.innerHTML = error.data.message;
            }
        },
        async verifyPhoneAndCode() {
            if (this.payload.sms.length !== 6) {
                this.$refs.smsError.innerHTML = this.$t("minimumNumberOfCharacters") + " - 6";
                return;
            }

            try {
                const response = await this.sendCode({
                    code: this.payload.sms,
                    phone: this.phone,
                    phone_country: this.countryCode,
                });
                /** @type {IVerifyCodeResponse} */
                const data = response.data;

                /*todo - check*/
                this.$toasted.success(data.message);

                this.changeRegistrationPopup(true);

                this.$refs.smsError.innerHTML = "";
            } catch (error) {
                this.$refs.smsError.innerHTML = error.data.message;
                // this.$toasted.error(error.data.message);
            }
        },
        async submit() {
            if (!this.smsCodeRequested) {
                await this.sendSmsCode();
                return;
            }

            await this.verifyPhoneAndCode();
        },
    },
};
