import { mapActions, mapGetters } from "vuex";
import defaultPrice from "../../../modules/buy-flow/pages/basket/components/default-price/index.vue";
import { map } from "lodash";
import { downloadFileFrom } from "@/helpers";

export default {
  name: 'default-prices',
  components: {
    defaultPrice,
  },
  data() {
    return {}
  },
  created() {
    document.getElementById('html').classList.add('hide')
  },
  computed: {
    ...mapGetters({
      productsPdf: 'order/productsPdf',
      basket: 'basket/basket',
      downloadPdfLoading: 'order/downloadPdfLoading',
      user: 'profile/user',
      isDistributor: 'profile/isDistributor',
      isProfessional: 'profile/isProfessional',
    }),
    someProductFree() {
      return this.basket.some(product => this.isFreePrice(product))
    },
    currentRound() {
      return 2
    },
    totalSumClient() {
      let sum = 0

      this.basket.map((product) => {
        console.log(product.select_type.clientPrice)
        if (product.select_type.clientPrice) {
          if (this.isFreePrice(product)) {
            sum += 0.01 * product.select_count
          } else {
            sum += (product.select_type.clientPrice || 0) * product.select_count
          }
        }
      })

      return sum
    },
  },
  methods: {
    ...mapActions({
      fetchPdf: `order/DOWNLOAD_PDF`,
    }),
    isSample(product) {
      return product.type === 'sampler'
    },
    async downloadPdf() {
      const products = map(this.basket, (product) => ({
        id: product.select_type.id,
        value: product.select_type.value,
        count: product.select_count,
        is_free: this.isFreePrice(product),
      }))

      await this.fetchPdf({ products })
      await downloadFileFrom(this.productsPdf.path, 'prices.pdf')
    },
    isFreePrice(product) {
      const isSampler = product.type === 'sampler'
      const isFreePrice = product.allProductSum / product.select_count === 0.01
      return isSampler && isFreePrice
    },
  },
}
