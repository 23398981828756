import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const isAuthenticated = (to, from, next) => {
    if (store.state.auth.isAuthenticated) {
        next();
        return;
    }
    next("/");
};

const routes = [
    {
        path: "/:lang(en|cz|ru|bg|da|de|el|en|es|et|fi|fr|ga|hr|hu|it|lb|lt|lv|mt|nl|pl|pt|ro|sk|sl|sv|tr)?/",
        name: "main",
        component: () => import(/* webpackChunkName: "main" */ /* webpackPrefetch: true */ "../modules/home/index.vue"),
    },
    {
        path: "/:lang(en|cz|ru|bg|da|de|el|en|es|et|fi|fr|ga|hr|hu|it|lb|lt|lv|mt|nl|pl|pt|ro|sk|sl|sv|tr)?/catalog",
        name: "products",
        component: () => import(/* webpackChunkName: "catalog" */ /* webpackPrefetch: false */ "../modules/catalog/index.vue"),
    },
    {
        path: "/:lang(en|cz|ru|bg|da|de|el|en|es|et|fi|fr|ga|hr|hu|it|lb|lt|lv|mt|nl|pl|pt|ro|sk|sl|sv|tr)?/promotions",
        name: "promotions",
        component: () => import(/* webpackChunkName: "promotions" */ /* webpackPrefetch: false */ "../modules/promotions/index.vue"),
    },
    {
        path: "/:lang(en|cz|ru|bg|da|de|el|en|es|et|fi|fr|ga|hr|hu|it|lb|lt|lv|mt|nl|pl|pt|ro|sk|sl|sv|tr)?/card/:slug",
        name: "card",
        component: () => import(/* webpackChunkName: "card" */ /* webpackPrefetch: false */ "../modules/card/index.vue"),
    },

    {
        path: "/:lang(en|cz|ru|bg|da|de|el|en|es|et|fi|fr|ga|hr|hu|it|lb|lt|lv|mt|nl|pl|pt|ro|sk|sl|sv|tr)?/server-maintenance",
        name: "server-maintenance",
        component: () => import(/* webpackChunkName: "server-maintenance" */ /* webpackPrefetch: false */ "../modules/system/server-maintenance/index.vue"),
    },
    {
        path: "/:lang(en|cz|ru|bg|da|de|el|en|es|et|fi|fr|ga|hr|hu|it|lb|lt|lv|mt|nl|pl|pt|ro|sk|sl|sv|tr)?/server-error",
        name: "server-error",
        component: () => import(/* webpackChunkName: "server-error" */ /* webpackPrefetch: false */ "../modules/system/server-error/index.vue"),
    },
    {
        path: "*",
        name: "page-not-found",
        component: () => import(/* webpackChunkName: "page-not-found" */ /* webpackPrefetch: false */ "../modules/system/page-not-found/index.vue"),
    },

    //--------------------------------------collaboration--------------------------------------
    {
        path: "/:lang(en|cz|ru|bg|da|de|el|en|es|et|fi|fr|ga|hr|hu|it|lb|lt|lv|mt|nl|pl|pt|ro|sk|sl|sv|tr)?/pages/spivpracya",
        name: "collaboration",
        component: () => import(/* webpackChunkName: "collaboration" */ /* webpackPrefetch: false */ "../modules/pages/collaboration/index.vue"),
    },
    //--------------------------------------pages--------------------------------------
    {
        path: "/:lang(en|cz|ru|bg|da|de|el|en|es|et|fi|fr|ga|hr|hu|it|lb|lt|lv|mt|nl|pl|pt|ro|sk|sl|sv|tr)?/pages/:slug",
        name: "page",
        component: () => import(/* webpackChunkName: "page" */ /* webpackPrefetch: false */ "../modules/pages/page-main/index.vue"),
        beforeEnter: (to, from, next) => {
            if (to.params.slug === "spivpracya") {
                console.log('REDIRECT');
                next({ name: 'collaboration' });
                return;
            }
            next()
        }
    },
    {
        path: "/:lang(en|cz|ru|bg|da|de|el|en|es|et|fi|fr|ga|hr|hu|it|lb|lt|lv|mt|nl|pl|pt|ro|sk|sl|sv|tr)?/pages/:slugPage/:slugIngredient",
        name: "sub-page",
        component: () => import(/* webpackChunkName: "sub-page" */ /* webpackPrefetch: false */ "../modules/pages/sub-page/index.vue"),
    },
    {
        path: "/:lang(en|cz|ru|bg|da|de|el|en|es|et|fi|fr|ga|hr|hu|it|lb|lt|lv|mt|nl|pl|pt|ro|sk|sl|sv|tr)?/contacts",
        name: "contact-page",
        component: () => import(/* webpackChunkName: "contact-page" */ /* webpackPrefetch: false */ "../modules/pages/contacts/index.vue"),
    },
    //--------------------------------------pages--------------------------------------
    {
        path: "/:lang(en|cz|ru|bg|da|de|el|en|es|et|fi|fr|ga|hr|hu|it|lb|lt|lv|mt|nl|pl|pt|ro|sk|sl|sv|tr)?/delivery",
        name: "delivery",
        component: () => import(/* webpackChunkName: "delivery" */ /* webpackPrefetch: false */ "../modules/pages/delivery-payment/index.vue"),
    },
    {
        path: "/:lang(en|cz|ru|bg|da|de|el|en|es|et|fi|fr|ga|hr|hu|it|lb|lt|lv|mt|nl|pl|pt|ro|sk|sl|sv|tr)?/seminars",
        name: "seminars",
        component: () => import(/* webpackChunkName: "seminars" */ /* webpackPrefetch: false */ "@/modules/teaching/index.vue"),
    },
    {
        path: "/:lang(en|cz|ru|bg|da|de|el|en|es|et|fi|fr|ga|hr|hu|it|lb|lt|lv|mt|nl|pl|pt|ro|sk|sl|sv|tr)?/results",
        name: "result",
        component: () => import(/* webpackChunkName: "search-catalog" */ /* webpackPrefetch: false */ "../modules/search-catalog/index.vue"),
    },
    {
        path: "/:lang(en|cz|ru|bg|da|de|el|en|es|et|fi|fr|ga|hr|hu|it|lb|lt|lv|mt|nl|pl|pt|ro|sk|sl|sv|tr)?/buy-flow",
        name: "buy-flow",
        redirect: "/buy-flow/basket",
        component: () => import(/* webpackChunkName: "buy-flow" */ /* webpackPrefetch: false */ "../modules/buy-flow/index.vue"),
        children: [
            {
                path: "basket",
                name: "buy-flow-basket",
                component: () => import(/* webpackChunkName: "buy-flow-basket" */ /* webpackPrefetch: false */ "../modules/buy-flow/pages/basket/index.vue"),
                meta: {
                    title: "Корзина",
                },
            },
            {
                path: "order",
                name: "buy-flow-order",
                component: () => import(/* webpackChunkName: "buy-flow-order" */ /* webpackPrefetch: false */ "../modules/buy-flow/pages/order/index.vue"),
                meta: {
                    title: "Оформлення замовлення",
                },
            },
        ],
    },
    // TODO: Renew remove profile route
    {
        path: "/:lang(en|cz|ru|bg|da|de|el|en|es|et|fi|fr|ga|hr|hu|it|lb|lt|lv|mt|nl|pl|pt|ro|sk|sl|sv|tr)?/profile",
        redirect: "/profile/user-info",
        component: () => import(/* webpackChunkName: "profile" */ /* webpackPrefetch: false */ "../modules/profile/index.vue"),
        name: "profile",
        beforeEnter: isAuthenticated,
        children: [
            {
                path: "user-info",
                name: "profile-user-info",
                component: () => import(/* webpackChunkName: "profile-user-info" */ /* webpackPrefetch: false */ "../modules/profile/pages/user-info/index.vue"),
            },
            {
                path: "user-current-orders",
                name: "profile-user-current-orders",
                component: () => import(/* webpackChunkName: "profile-user-current-orders" */ /* webpackPrefetch: false */ "../modules/profile/pages/current-orders/index.vue"),
            },
            {
                path: "user-history-orders",
                name: "profile-user-history-orders",
                component: () => import(/* webpackChunkName: "profile-user-history-orders" */ /* webpackPrefetch: false */ "../modules/profile/pages/history-orders/index.vue"),
            },
            {
                path: "platform",
                name: "platform",
                component: () => import(/* webpackChunkName: "profile-platform" */ /* webpackPrefetch: false */ "@/modules/profile/pages/platform/index.vue"),
                redirect: "/profile/platform/seminars",
                children: [
                    {
                        path: "seminars",
                        name: "platform-seminars",
                        component: () => import(/* webpackChunkName: "profile-platform-seminars" */ /* webpackPrefetch: false */ "@/modules/profile/pages/platform/pages/seminars/index.vue"),
                    },
                    {
                        path: "webinar/:id",
                        name: "webinar",
                        component: () => import(/* webpackChunkName: "profile-platform-webinar" */ /* webpackPrefetch: false */ "@/modules/profile/pages/platform/pages/webinars/index.vue"),
                    },
                    {
                        path: "webinar/:id/quiz",
                        name: "quiz",
                        component: () => import(/* webpackChunkName: "profile-platform-quiz" */ /* webpackPrefetch: false */ "@/modules/profile/pages/platform/pages/test/index.vue"),
                    },
                    {
                        path: "webinar/:id/quiz-result",
                        name: "quiz-result",
                        component: () => import(/* webpackChunkName: "profile-platform-quiz-result" */ /* webpackPrefetch: false */ "@/modules/profile/pages/platform/pages/test-result/index.vue"),
                    },
                ],
            },
            {
                path: "user-favorites",
                name: "profile-user-favorites",
                component: () => import(/* webpackChunkName: "profile-user-favorites" */ /* webpackPrefetch: false */ "../modules/profile/pages/favorites/index.vue"),
            },
            {
                path: "user-professional-club",
                name: "user-professional-club",
                redirect: "user-professional-club/clients-list",

                component: () => import(/* webpackChunkName: "profile-professional-club" */ /* webpackPrefetch: false */ "../modules/profile/pages/professional-club/index.vue"),
                children: [
                    {
                        path: "clients-list",
                        name: "profile-clients-list",
                        component: () => import(/* webpackChunkName: "profile-professional-club-clients-list" */ /* webpackPrefetch: false */ "../modules/profile/pages/professional-club/pages/clients-list/index.vue"),
                    },
                    {
                        path: "client-history/:id",
                        name: "profile-client-history",
                        component: () => import(/* webpackChunkName: "profile-professional-club-client-history" */ /* webpackPrefetch: false */ "../modules/profile/pages/professional-club/pages/client-history/index.vue"),
                    },
                ],
            },
        ],
    },
];
const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes: routes,
    scrollBehavior(to, from, savedPosition) {
        const el = document.querySelector(".app");
        if (to.path !== from.path) {
            if (savedPosition) {
                return savedPosition;
            } else {
                if (el) el.scrollTop = 0;
                return { x: 0, y: 0 };
            }
        }
    },
});

export default router;
