<template>
    <div class="loader">
        <div class="preloader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</template>
<script>
export default {
    name: "loader",
};
</script>
<style lang="scss">
.loader {
    display: block;
}

.preloader {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    animation: rotatePreloader 2s infinite ease-in;
}

@keyframes rotatePreloader {
    0% {
        transform: translateX(-50%) translateY(-50%) rotateZ(0deg);
    }
    100% {
        transform: translateX(-50%) translateY(-50%) rotateZ(-360deg);
    }
}

.preloader div {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.preloader div:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    width: 10%;
    height: 10%;
    background-color: #000000;
    transform: translateX(-50%);
    border-radius: 50%;
}

@for $i from 1 through 10 {
    .preloader div:nth-child(#{$i}) {
        transform: rotateZ(((360 / 10) * ($i - 1)) + deg);
        animation: rotateCircle + $i 2s infinite linear;
        z-index: 10 - $i;
    }
    @keyframes rotateCircle#{$i} {
        #{percentage(((50 / 10) * ($i - 1)) / 100)} {
            opacity: 0;
        }
        #{percentage((((50 / 10) + 0.0001) * ($i - 1)) / 100)} {
            opacity: 1;
            transform: rotateZ((0 - ((360 / 10) * ($i - 2))) + deg);
        }
        #{percentage((((50 / 10) * ($i - 0)) + 2) / 100)} {
            transform: rotateZ((0 - ((360 / 10) * ($i - 1))) + deg);
        }
        #{percentage(((50 + ((50 / 10) * ($i - 0))) + 2) / 100)} {
            transform: rotateZ((0 - ((360 / 10) * ($i - 1))) + deg);
        }
        100% {
            transform: rotateZ((0 - ((360 / 10) * (10 - 1))) + deg);
            opacity: 1;
        }
    }
}
</style>
