export enum UserTypes {
    Distributor = "distributor",
    Professional = "professional",
    Authorized_user = "authorized_user",
    Unauthorized_user = "unauthorized_user",
}

type RegularUser = {
    userType: {
        title: string;
        name: UserTypes.Unauthorized_user;
    };
};

type AuthUser = {
    assignedCode: string;

    userType: {
        title: string;
        name: UserTypes.Authorized_user;
    };
};

type ProfessionalUser = {
    individualDiscount: number;

    salesLastMonth: number;
    salesThisMonth: number;
    debits: number;

    userType: {
        title: string;
        name: UserTypes.Professional;
    };
};

type DistributorUser = {
    individualDiscount: number;

    salesLastMonth: number;
    salesThisMonth: number;
    debits: number;

    userType: {
        title: string;
        name: UserTypes.Distributor;
    };
};

export type User = (RegularUser | AuthUser | ProfessionalUser | DistributorUser) & {
    id: number;
    active: boolean;
    email: string;
    name: string;
    secondName: string;
    lastName: string;
    phone: string;
    country: string;
    city: string;
    work: string;
    additionalInformation: string;
    createdAt: string;
    ordersCount: number;

    bonusAmount: number;
    canUseBonuses: boolean;

    code: string | null;

    assignTo: { data?: User | null };
    files: { data?: Array<UserFile> };
    clients: { data?: Array<ClientUser> };
};

export interface ClientUser {
    id: number;
    active: boolean;
    email: string;
    name: string;
    secondName: string;
    lastName: string;
    phone: string;
    country: string;
    city: string;
    work: string;
    tempDiscount: boolean;
    additionalInformation: string;
    createdAt: string;

    files: { data?: Array<UserFile> };
}

export interface UserFile {
    id: number;
    userId: number;
    path: string;
}
