import { mapGetters, mapMutations } from "vuex";
import { getProductPriceTitle, resolveProductImage, routeToProduct } from "@/helpers";

export default {
    name: "default-price",
    props: {
        product: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            selectedCount: 1,
        };
    },
    computed: {
        ...mapGetters({
            basket: "basket/basket",
            productsPdf: "order/productsPdf",
            currentRegionCurrency: "regions/currentRegionCurrency",
        }),
    },
    methods: {
        getProductPriceTitle,
        routeToProduct,
        resolveProductImage,
        ...mapMutations({
            increment: "basket/INCREMENT_BASKET_LIST_COUNT",
            decrement: "basket/DECREMENT_BASKET_LIST_COUNT",
            setCount: "basket/SET_BASKET_LIST_COUNT",
            remove: "basket/REMOVE_ITEM_FROM_BASKET",
        }),
        isSample(product) {
            return product.type === 'sampler';
        },
        allowedProductManipulations(product) {
            return !product.isAddedFromSale && !this.isSample(product) && !product.isPresent
        },
        changeCount(type, count = 1) {
            if (type === "increment") {
                this.increment(this.product);
            } else if (type === "decrement") {
                this.decrement(this.product);
            } else {
                if (count) {
                    this.setCount({
                        product: this.product,
                        select_count: parseInt(count),
                    });
                }
            }
        },
        findProductInBasket(id) {
            return this.basket.find(product => product.id === id);
        },
        isSample(product) {
            const productInBasket = this.findProductInBasket(product.id);
            const isSampler = productInBasket.type === 'sampler';
            const isFreePrice = productInBasket.allProductSum / productInBasket.select_count === 0.01;
            return isSampler && isFreePrice && product.select_type.clientPrice;
        },
    },
};
