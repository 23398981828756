export const PageConfigType = {
    AmbassadorialProgram: 'ambassadorial-program',
    ApplicationForPresentation: 'application-for-presentation',
    BeforeAfter: 'before-after',
    BrandGeolocation: 'brand-geolocation',
    BrandHistory: 'brand-history',
    Catalog: 'catalog',
    CatalogAndProtocols: 'catalog-and-protocols',
    ContactPage: 'contact-page',
    CosmetologistId: 'cosmetologist-id',
    DeliveryAndPayment: 'delivery-and-payment',
    FrequentlyAskedQuestions: 'frequently-asked-questions',
    Home: 'home',
    InnovativeIngredients: 'innovative-ingredients',
    OnlineEducation: 'online-education',
    OpisanieFormatovObuchenij: 'opisanie-formatov-obuchenij',
    OurSeries: 'our-series',
    Partners: 'partners',
    Patents: 'patents',
    PrivacyPolicy: 'privacy-policy',
    PublicOffer: 'public-offer',
    QualityCertificates: 'quality-certificates',
    Renew: 'renew',
    ScientificNews: 'scientific-news',
    Seminars: 'seminars',
    TermsOfCooperation: 'terms-of-cooperation',
    TermsOfUse: 'terms-of-use',
    VideoObucheniya: 'video-obucheniya',
    VideoPresentation: 'video-presentation',
    VideoPresentationRenew: 'video-presentation-renew',
    WhyBrand: 'why-brand',
}

export const NavItemType = {
    BrandItem: "brand_item",
    BrandSeriesItem: "brand_series_item",
    CatalogItem: "catalog",
    MenuItem: "menu_item",
    PageItem: "page_item",
    ProductItem: "product",
    SinglePageMenuItem: "single_page_menu_item",
    ProductDiscountsItem: "product_discounts_item",
};

const availableMenuItemTypes = Object.values(NavItemType);

/**
 * @param {NavigationItem} navItem
 * @return {boolean}
 */
export function isProcessableItem({ itemType }) {
    return availableMenuItemTypes.includes(itemType);
}

/**
 * @param {NavigationItem} navItem
 * @return {boolean}
 */
export function isClickableInMainMenu(navItem) {
    return navItem.itemType === NavItemType.SinglePageMenuItem || navItem.isLink;
}

/**
 * @param {NavigationItem} navItem
 * @return {boolean}
 */
export function isClickableInSidebarMenu(navItem) {
    return navItem.itemType === NavItemType.SinglePageMenuItem;
}

/**
 * @param {NavigationItem} navItem
 * @param {NavigationItem|null} parentNavItem
 * @return {Object}
 */
export function routeForMenuItem(navItem, parentNavItem = null) {
    const { itemType, slug } = navItem;

    if (parentNavItem) {
        if (slug === "seminars" && parentNavItem.slug === "professional") {
            return { name: "seminars" };
        }

        if (parentNavItem.slug === "products") {
            if (slug === "catalog-discounts") {
                return {
                    name: "products",
                    query: { sort_filter_type: "with_promotions" },
                };
            }

            if (itemType === NavItemType.BrandItem) {
                return {
                    name: "products",
                    query: { brands: slug },
                };
            }
        }

        if (parentNavItem.itemType === NavItemType.BrandItem) {
            if (itemType === NavItemType.BrandSeriesItem) {
                return {
                    name: "products",
                    query: { brands: parentNavItem.slug, series: slug },
                };
            }
        }
    }

    if ([NavItemType.SinglePageMenuItem, NavItemType.PageItem].includes(itemType)) {
        if (slug === 'spivpracya' ) {
            return { name: "collaboration", params: { slug } };
        }
        return { name: "page", params: { slug } };
    }
    if (NavItemType.ProductItem === itemType) {
        return { name: "card", params: { slug } };
    }
    if (NavItemType.CatalogItem === itemType || slug === "products") {
        return { name: "products" };
    }

    return { name: "page", params: { slug } };
}
