//utils
import "./utils/plugins";
import "./utils/translations";
import "./utils/global-components";
import VueTheMask from "vue-the-mask";
import VueSmoothScroll from "vue2-smooth-scroll";
import moment from "moment";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import VueHead from "vue-head";

import VTooltip from "v-tooltip";
import { $http } from "@/utils/https";

Vue.use(VTooltip)

Vue.use(VueSmoothScroll, {
    duration: 400,
    offset: -380,
});
Vue.prototype.moment = moment;
Vue.use(VueTheMask);
Vue.use(VueHead);
Vue.config.productionTip = false;

if (localStorage.getItem("user_token")) {
    store.commit(`auth/SET_TOKEN`, localStorage.getItem("user_token"));
}

const app = new Vue({
    router,
    store,
    render: h => h(App),
    mounted() {
        document.dispatchEvent(new Event("render-event"));
    },
});

const eventHub = new Vue();
Vue.mixin({
    data: function () {
        return {
            eventHub: eventHub,
        };
    },
});

const DEFAULT_LOCALE = "en";

const fetchCurrentRegionData = async () => {
    const response = await $http.get(`v1/regions/current`);
    store.commit("regions/SET_CURRENT_REGION", response.data);
    const countryCode = response.data.alpha2Code;

    if (localStorage.getItem('country') !== countryCode) {
      localStorage.setItem('country', countryCode);
    }

    return response.data;
};

const getAppLocale = () => {
    if (!localStorage.getItem('app_locale')) {
        localStorage.setItem('app_locale', DEFAULT_LOCALE);
    }
    if (localStorage.getItem('app_locale') === 'cs') {
        localStorage.setItem('app_locale', 'cz');
    }
    return localStorage.getItem('app_locale') || DEFAULT_LOCALE;
};

const syncUrlLocale = (appLocale: string) => {
    const currentRoute = router.currentRoute
    const currentLang = currentRoute.params.lang

    if (currentLang === undefined || currentLang === null || currentLang !== appLocale) {
        router
            .replace({
                params: { ...currentRoute.params, lang: appLocale },
                query: { ...currentRoute.query },
            })
            .catch((err): void => {

            })
    }
}

const mountApp = () => {
    try {
        app.$mount("#app");
    } catch (e) {
        console.error("Error mounting app:", e);
    }
};

(async () => {
    const appLocale = getAppLocale();
    await fetchCurrentRegionData();
    (window as any).currentLocale = appLocale;
    (Vue as any).initI18nManager().then(() => {
        syncUrlLocale(appLocale)
        document.querySelector('.main-loader')?.remove();
        mountApp();
    });
})()

