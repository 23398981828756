
export default {
    name: 'LanguageSelect',
    data() {
        return {
            languages: [
                { code: 'bg', name: 'Bulgarian' },
                { code: 'cz', name: 'Czech' },
                { code: 'da', name: 'Danish' },
                { code: 'de', name: 'German' },
                { code: 'el', name: 'Greek' },
                { code: 'en', name: 'English' },
                { code: 'es', name: 'Spanish' },
                { code: 'et', name: 'Estonian' },
                { code: 'fi', name: 'Finnish' },
                { code: 'fr', name: 'French' },
                { code: 'hu', name: 'Hungarian' },
                { code: 'it', name: 'Italian' },
                { code: 'lt', name: 'Lithuanian' },
                { code: 'lv', name: 'Latvian' },
                { code: 'nl', name: 'Dutch' },
                { code: 'pl', name: 'Polish' },
                { code: 'pt', name: 'Portuguese' },
                { code: 'ro', name: 'Romanian' },
                { code: 'ru', name: 'Russian' },
                { code: 'sk', name: 'Slovak' },
                { code: 'sl', name: 'Slovenian' },
                { code: 'sv', name: 'Swedish' },
                { code: 'tr', name: 'Turkish' },
            ],
            isOpen: false,
        }
    },
    computed: {
        sortedLanguages() {
            return this.languages.sort((a, b) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
            });
        },
        currentLanguage() {
            return this.languages.find(language => language.code === localStorage.getItem('app_locale'));
        }
    },
    methods: {
        openLanguageSelector() {
            this.isOpen = !this.isOpen;
        },

        selectLanguage(language) {
            localStorage.setItem('app_locale', language.code);
            this.isOpen = false;
            window.location.reload();
        }
    }
}
